<template>

<div id="wrap">
    <!-- 오버레이 처리 -->
    <div id="overlay"></div>

    <!-- 참조 -->
    <div id="config"></div>

    <!-- 메인 -->
    <div id="body" class="fullscreen">
        <div class="cont" id="p04"
			v-if="!result.success && !result.password"
		>
            <div class="c01">
                <div class="cont-modal">
                    <div class="modal">
                        <div class="logo" style="font-size: 2em; padding-bottom: 10px; border-bottom: 2px solid #ddd">{{ codes.metaInfo.title }} <span style="position: absolute; right: 50px; top: 50px; font-size: 0.5em;">franchisee</span></div>
                        <div class="top flex-column">
                            <div class="title">
                                <span class="tp"><router-link :to="{ name: 'AuthFindId'}">아이디 찾기</router-link></span>
                                <span class="tp active"><a >비밀번호 찾기</a></span>
                            </div>
                            <div class="rd">가입시 입력하신 연락처를 통해 찾을 수 있습니다.</div>
                        </div>
                        <div class="form">
                            <input id="ip01" type="text" placeholder="아이디를 입력하세요."
								v-model="item.userId"
							>
                            <input id="ip02" type="text" placeholder="가입 시 핸드폰 번호를 입력하세요."
								v-model="item.phoneNumber"
							>
                            <div class="ad">
                                <div class="p">핸드폰 입력 시 -을 제외한 숫자만 입력해주세요.</div>
                                <div class="err"
									:class="{ active: error.info }"> <!-- 입력 오류시 class "active" -->
                                    입력하신 정보가 일치하지 않습니다.
                                </div>
                            </div>
                        </div>
                        <div class="cont-btn">
                            <a @click="post"><div class="btn-type02">휴대폰 인증</div></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright">
                Copyright © {{ codes.metaInfo.copyright }} Corp. All Rights Reserved.
            </div>
        </div>
		
        <div class="cont" id="p05"
			v-if="result.success && !result.password "
		>
            <div class="c01">
                <div class="cont-modal">
                    <div class="modal">
                        <div class="logo"><img src="@/assets/img/logo02.png" alt=""></div>
                        <div class="top">
                            <div class="title">
                                <div class="p1">본인인증이 완료되었습니다.<br>비밀번호를 변경하세요.</div>
                            </div>
                        </div>
                        <div class="form">
                            <input type="password" placeholder="신규 비밀번호"
								v-model="item.newPassword"
							>
                            <input type="password" placeholder="비밀번호 확인"
								v-model="newPassword"
							>
                            <div class="ad">
                                <div class="p">영문, 숫자, 특수문자를 포함한 8자 이상</div>
                                <div class="err"
									:class="{ active: error.password }"> <!-- 입력 오류시 class "active" -->
                                    비밀번호가 다릅니다.
                                </div>
                            </div>
                        </div>
                        <div class="cont-btn">
                            <a @click="password"><div class="btn-type02">확인</div></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright">
                Copyright © {{ codes.metaInfo.copyright }} Corp. All Rights Reserved.
            </div>
			
        </div>
		<div class="cont" id="p03"
			v-if="result.success && result.password"
		>
			<div class="c01">
				<div class="cont-modal">
					<div class="modal">
						<div class="logo"><img src="@/assets/img/logo02.png" alt=""></div>
						<div class="sc-result">
							<div class="title">비밀번호 변경 안내</div>
							
							<div class="desc">
								비밀번호가 정상적으로 변경되었습니다
                            </div>
						</div>                        
						<div class="cont-btn">
							<router-link :to="'/Login'"><div class="btn-type01">로그인 하러가기</div></router-link>
						</div>
					</div>
				</div>
			</div>
			<div class="copyright">
				Copyright © {{ codes.metaInfo.copyright }} Corp. All Rights Reserved.
			</div>
		</div>
    </div>
</div>

</template>


<script>

	import { Axios } from '@/resources/axios/axios'
	
	export default {
		name: 'AuthLogin'
		,props: ['codes']
		,data: function(){
			return {
				program: {
					not_header: true
				}
				,item: {
					userId: ''
					,phoneNumber: ''
					,newPassword: ''
					,userSeq: ''
				}
				,error: {
					info: false
					,password: false
				}
				,result: {
					success: false
					,password: false
				}
				,newPassword: ''
				
			}
		}
		,methods: {
			post: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/users/find/user'
						,data: this.item
					})
					
					if(result.success){
						this.error.info = false
						this.result.success = true
						this.item.userSeq = result.data.content
					}else{
						this.error.info = true
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E.message })
				}
			}
			,password: async function(){
				try{
					const result = await Axios({
						method: 'post'
						,url: '/users/password'
						,data: this.item
					})
					
					if(result.success){
						this.error.password = false
						this.result.password = true
					}else{
						this.error.password = false
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E.message })
				}
			}
		}
		,created: function(){
			this.$emit('setProgram', this.program)
		}
		,watch: {
			newPassword: {
				handler: function(call){
					if(call != this.item.newPassword){
						this.error.password = true
					}
				}
			}
		}
	}
</script>












